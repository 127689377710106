import PropTypes from "prop-types";
import React from "react";

import { Link } from "gatsby";

import { useStyletron } from "baseui";
import { LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography";

import { Grid, Cell, Button } from "../ui";
import { FormattedContentItem, ContentItem, ContentObject } from "../content";
import { useTheme } from "../../contexts/theme";

import logo from "../../assets/images/logo-white.svg";
import clutchBadge from "../../assets/images/clutch-badge.svg";
//import sodaLogo from "../../assets/images/soda-logo.svg";
import awsLogo from "../../assets/images/aws-logo.svg";
import atendeLogo from "../../assets/images/partners/atende.svg";
import clutchLogo from "../../assets/images/clutch-logo.svg";
import clutchLogoWhite from "../../assets/images/clutch-logo-white.svg";
import Social from "../../assets/images/social";

const Footer = ({ size }) => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  if (size === "regular") {
    return (
      <footer
        data-csweb="footer"
        className={css({
          zIndex: 1,
          position: "relative",
        })}
      >
        <div
          className={css({
            backgroundColor: theme.colors.primary,
            overflow: "hidden",
            position: "relative",
          })}
        >
          <Grid>
            <Cell skip={[0, 0, 1]} span={[2, 4, 3]}>
              <div
                className={css({
                  lineHeight: "0.5",
                  paddingTop: theme.sizing.scale1200,
                  paddingBottom: theme.sizing.scale1200,
                  [theme.breakpoints.mediaQueries.small]: {
                    paddingTop: theme.sizing.scale1200,
                    paddingBottom: theme.sizing.scale200,
                    textAlign: "center",
                  },
                })}
              >
                <img
                  alt="Codeshine"
                  className={css({
                    maxWidth: "220px",
                    transitionProperty: "all",
                    transitionDuration: "200ms",
                    transitionTimingFunction: "ease-in-out",
                  })}
                  src={logo}
                />

                <LabelSmall
                  $style={{
                    color: theme.colors.white,
                    fontWeight: "400",
                    marginTop: theme.sizing.scale800,
                    marginBottom: theme.sizing.scale800,
                  }}
                >
                  <FormattedContentItem
                    scope="Footer"
                    accessor="regular.claim"
                  />
                </LabelSmall>

                <div
                  className={css({
                    display: "flex",
                    alignItems: "flex-end",
                    marginTop: theme.sizing.scale1600,
                    paddingTop: theme.sizing.scale400,
                    [theme.breakpoints.mediaQueries.small]: {
                      justifyContent: "center",
                      marginTop: theme.sizing.scale600,
                      marginBottom: theme.sizing.scale600,
                    },
                  })}
                >
                  <LabelXSmall
                    $style={{
                      textTransform: "uppercase",
                      color: theme.colors.white,
                      letterSpacing: "1px",
                      lineHeight: "15px",
                      marginRight: theme.sizing.scale600,
                      [theme.breakpoints.mediaQueries.small]: {
                        display: "none",
                      },
                    }}
                  >
                    <ContentItem scope="Footer" accessor="shared.socialLabel" />
                  </LabelXSmall>
                  <a
                    href={ContentObject.Shared.linkedInLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button kind="circle" color="white">
                      <img
                        alt="LinkedIn"
                        src={Social.LinkedIn}
                        width="16px"
                        height="16px"
                      />
                    </Button>
                  </a>
                </div>
              </div>
            </Cell>
            <Cell span={[1, 2, 2]}>
              <div
                className={css({
                  paddingTop: theme.sizing.scale1200,
                  paddingBottom: theme.sizing.scale1200,
                  [theme.breakpoints.mediaQueries.small]: {
                    paddingTop: theme.sizing.scale200,
                    paddingBottom: theme.sizing.scale200,
                    textAlign: "center",
                  },
                })}
              >
                <LabelMedium
                  color="white"
                  $style={{
                    [theme.breakpoints.mediaQueries.small]: {
                      lineHeight: "48px",
                    },
                  }}
                >
                  <ContentItem
                    scope="Footer"
                    accessor="regular.navigation.0.title"
                  />
                </LabelMedium>
                <div
                  className={css({
                    paddingLeft: 0,
                    paddingRight: 0,
                    listStyle: "none",
                    color: theme.colors.white,
                  })}
                >
                  {ContentObject.Footer.regular.navigation[0].items.map(
                    (item, index) => (
                      <Link
                        key={index}
                        to={item.to}
                        className={css({
                          textDecoration: "none",
                          display: "block",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionTimingFunction: "ease-in-out",
                          ":hover": {
                            opacity: "0.8",
                          },
                        })}
                      >
                        <LabelSmall
                          $style={{
                            color: theme.colors.white,
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "15px",
                            lineHeight: "26px",
                            [theme.breakpoints.mediaQueries.small]: {
                              lineHeight: "48px",
                            },
                          }}
                        >
                          {item.title}
                        </LabelSmall>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </Cell>
            <Cell span={[1, 2, 2]}>
              <div
                className={css({
                  paddingTop: theme.sizing.scale1200,
                  paddingBottom: theme.sizing.scale1200,
                  [theme.breakpoints.mediaQueries.small]: {
                    paddingTop: theme.sizing.scale200,
                    paddingBottom: theme.sizing.scale800,
                    textAlign: "center",
                  },
                })}
              >
                <LabelMedium
                  color="white"
                  $style={{
                    [theme.breakpoints.mediaQueries.small]: {
                      lineHeight: "48px",
                    },
                  }}
                >
                  <ContentItem
                    scope="Footer"
                    accessor="regular.navigation.1.title"
                  />
                </LabelMedium>
                <div
                  className={css({
                    paddingLeft: 0,
                    paddingRight: 0,
                    listStyle: "none",
                    color: theme.colors.white,
                  })}
                >
                  {ContentObject.Footer.regular.navigation[1].items.map(
                    (item, index) => (
                      <Link
                        key={index}
                        to={item.to}
                        className={css({
                          textDecoration: "none",
                          display: "block",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionTimingFunction: "ease-in-out",
                          ":hover": {
                            opacity: "0.8",
                          },
                        })}
                      >
                        <LabelSmall
                          $style={{
                            color: theme.colors.white,
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "15px",
                            lineHeight: "26px",
                            [theme.breakpoints.mediaQueries.small]: {
                              lineHeight: "48px",
                            },
                          }}
                        >
                          {item.title}
                        </LabelSmall>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </Cell>
            <Cell span={[4, 8, 4]}>
              <div
                className={css({
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                })}
              >
                <div
                  className={css({
                    flexGrow: "1",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  })}
                >
                  <div
                    className={css({
                      backgroundColor: theme.colors.backgroundPrimary,
                      width: "50vw",
                      display: "block",
                      height: "100%",
                      position: "absolute",
                      [theme.breakpoints.mediaQueries.large]: {
                        left: "-64px",
                        width: "auto",
                        right: "-64px",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                      },
                    })}
                  ></div>
                  <div
                    className={css({
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      [theme.breakpoints.mediaQueries.medium]: {
                        paddingTop: theme.sizing.scale400,
                        paddingBottom: theme.sizing.scale400,
                      },
                    })}
                  >
                    <LabelXSmall
                      $style={{
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        marginRight: theme.sizing.scale750,
                      }}
                    >
                      <ContentItem
                        scope="Footer"
                        accessor="shared.clutchLabel"
                      />
                    </LabelXSmall>
                    <a
                      href={ContentObject.Shared.clutchLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img alt="Clutch" src={clutchBadge} height="82px" />
                    </a>
                  </div>
                </div>
                <div
                  className={css({
                    flexGrow: "1",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  })}
                >
                  <div
                    className={css({
                      backgroundColor: theme.colors.backgroundLightAccent,
                      width: "50vw",
                      display: "block",
                      height: "100%",
                      position: "absolute",
                      borderBottomLeftRadius: "30px",
                      [theme.breakpoints.mediaQueries.large]: {
                        left: "-64px",
                        width: "auto",
                        right: "-64px",
                        borderBottomLeftRadius: "0px",
                      },
                    })}
                  ></div>
                  <div
                    className={css({
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      position: "relative",
                    })}
                  >
                    {/* <div
                      className={css({
                        overflow: "hidden",
                        lineHeight: "0.5",
                        paddingTop: theme.sizing.scale600,
                        paddingBottom: theme.sizing.scale600,
                        cursor: "pointer",
                        ":hover [data-csweb=logo-label]": {
                          transform: "translateY(0px)",
                          opacity: "1",
                        },
                        ":hover img": {
                          transform: "translateY(0px)",
                          filter: "unset",
                        },
                      })}
                    >
                      <img
                        className={css({
                          filter: "brightness(0) invert(1)",
                          transitionProperty: "all",
transitionDuration: "300ms",
transitionTimingFunction: "ease-in-out",
                          transform: "translateY(0px)",
                          marginTop: theme.sizing.scale400,
                        })}
                        src={sodaLogo}
                        height="30px"
                      />
                      <LabelXSmall
                        data-csweb="logo-label"
                        $style={{
                          textTransform: "uppercase",
                          letterSpacing: "2px",
                          marginTop: theme.sizing.scale400,
                          transitionProperty: "all",
transitionDuration: "300ms",
transitionTimingFunction: "ease-in-out",
                          transform: "translateY(20px)",
                          textAlign: "center",
                          opacity: "0",
                          fontSize: "9px",
                        }}
                      >
                        Membership
                      </LabelXSmall>
                    </div> */}
                    <div
                      className={css({
                        overflow: "hidden",
                        lineHeight: "0.5",
                        paddingTop: theme.sizing.scale600,
                        paddingBottom: theme.sizing.scale600,
                        cursor: "pointer",
                        ":hover [data-csweb=logo-label]": {
                          transform: "translateY(0px)",
                          opacity: "1",
                        },
                        ":hover img": {
                          transform: "translateY(0px)",
                          filter: "unset",
                        },
                      })}
                    >
                      <img
                        className={css({
                          filter: "brightness(0) invert(1)",
                          transitionProperty: "all",
                          transitionDuration: "300ms",
                          transitionTimingFunction: "ease-in-out",
                          transform: "translateY(0px)",
                          marginTop: theme.sizing.scale400,
                        })}
                        src={awsLogo}
                        height="30px"
                        alt="AWS"
                      />
                      <LabelXSmall
                        data-csweb="logo-label"
                        $style={{
                          textTransform: "uppercase",
                          letterSpacing: "2px",
                          marginTop: theme.sizing.scale400,
                          transitionProperty: "all",
                          transitionDuration: "300ms",
                          transitionTimingFunction: "ease-in-out",
                          transform: "translateY(20px)",
                          textAlign: "center",
                          opacity: "0",
                          fontSize: "9px",
                        }}
                      >
                        Partnership
                      </LabelXSmall>
                    </div>
                  </div>
                </div>
              </div>
            </Cell>
            <Cell span={8}>
              <div
                className={css({
                  position: "relative",
                })}
              >
                <div
                  className={css({
                    position: "absolute",
                    zIndex: "0",
                    right: "-36px",
                    left: "0px",
                    height: "20vh",
                    backgroundColor: theme.colors.black100,
                    borderTopRightRadius: "30px",
                    [theme.breakpoints.mediaQueries.large]: {
                      borderTopRightRadius: "0px",
                      left: "-64px",
                      height: "40vh",
                    },
                  })}
                ></div>
              </div>
            </Cell>
            <Cell span={[0, 0, 4]}>
              <div
                className={css({
                  position: "relative",
                })}
              >
                <div
                  className={css({
                    position: "absolute",
                    zIndex: "0",
                    left: "0px",
                    height: "20vh",
                    width: "50vw",
                    backgroundColor: theme.colors.black100,
                    borderTopLeftRadius: "30px",
                  })}
                ></div>
              </div>
            </Cell>
            <Cell skip={[0, 0, 1]} span={[4, 4, 7]} order={[12, 10, 10]}>
              <LabelSmall
                $style={{
                  fontWeight: "400",
                  color: theme.colors.white,
                  marginTop: theme.sizing.scale800,
                  marginBottom: theme.sizing.scale800,
                  position: "relative",
                  [theme.breakpoints.mediaQueries.large]: {
                    textAlign: "center",
                    marginTop: theme.sizing.scale200,
                  },
                }}
              >
                <ContentItem scope="Footer" accessor="shared.copyrights" />
              </LabelSmall>
            </Cell>
            <Cell span={[4, 4, 4]} order={[10, 12, 12]}>
              <div
                className={css({
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  marginTop: theme.sizing.scale800,
                  marginBottom: theme.sizing.scale800,
                })}
              >
                <LabelSmall
                  $style={{
                    fontWeight: "400",
                    color: theme.colors.white,
                  }}
                >
                  <ContentItem scope="Footer" accessor="shared.investorLabel" />
                </LabelSmall>

                <a
                  href={ContentObject.Footer.shared.investorUrl}
                  title={ContentObject.Footer.shared.investorName}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={atendeLogo}
                    alt={ContentObject.Footer.shared.investorName}
                    height="14px"
                    className={css({
                      marginLeft: theme.sizing.scale600,
                      filter: "brightness(0) invert(1)",
                    })}
                  />
                </a>
              </div>
            </Cell>
          </Grid>
        </div>
      </footer>
    );
  } else {
    return (
      <footer data-csweb="footer">
        <div
          className={css({
            backgroundColor: theme.colors.primary,
            overflow: "hidden",
            position: "relative",
          })}
        >
          <Grid>
            <Cell skip={[0, 0, 1]} span={[2, 2, 3]} align="flex-end">
              <div
                className={css({
                  lineHeight: "0.5",
                  paddingBottom: theme.sizing.scale1000,
                  [theme.breakpoints.mediaQueries.small]: {
                    paddingTop: theme.sizing.scale1200,
                    paddingBottom: theme.sizing.scale200,
                    textAlign: "center",
                  },
                })}
              >
                <img
                  alt="Codeshine"
                  className={css({
                    maxWidth: "220px",
                    transitionProperty: "all",
                    transitionDuration: "200ms",
                    transitionTimingFunction: "ease-in-out",
                  })}
                  src={logo}
                />
              </div>
            </Cell>
            <Cell span={[1, 1, 2]}>
              <div
                className={css({
                  paddingTop: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale600,
                  [theme.breakpoints.mediaQueries.small]: {
                    paddingTop: theme.sizing.scale200,
                    paddingBottom: theme.sizing.scale0,
                    textAlign: "center",
                  },
                })}
              >
                <div
                  className={css({
                    paddingLeft: 0,
                    paddingRight: 0,
                    listStyle: "none",
                    color: theme.colors.white,
                    marginTop: theme.sizing.scale600,
                    marginBottom: theme.sizing.scale600,
                  })}
                >
                  {ContentObject.Footer.compact.navigation[0].items.map(
                    (item, index) => (
                      <Link
                        key={index}
                        to={item.to}
                        className={css({
                          textDecoration: "none",
                          display: "block",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionTimingFunction: "ease-in-out",
                          ":hover": {
                            opacity: "0.8",
                          },
                        })}
                      >
                        <LabelSmall
                          $style={{
                            color: theme.colors.white,
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "15px",
                            lineHeight: "26px",
                            [theme.breakpoints.mediaQueries.small]: {
                              lineHeight: "48px",
                            },
                          }}
                        >
                          {item.title}
                        </LabelSmall>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </Cell>
            <Cell span={[1, 2, 2]}>
              <div
                className={css({
                  paddingTop: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale600,
                  [theme.breakpoints.mediaQueries.small]: {
                    paddingTop: theme.sizing.scale0,
                    paddingBottom: theme.sizing.scale800,
                    textAlign: "center",
                  },
                })}
              >
                <div
                  className={css({
                    paddingLeft: 0,
                    paddingRight: 0,
                    listStyle: "none",
                    color: theme.colors.white,
                    marginTop: theme.sizing.scale600,
                    marginBottom: theme.sizing.scale600,
                  })}
                >
                  {ContentObject.Footer.compact.navigation[1].items.map(
                    (item, index) => (
                      <Link
                        key={index}
                        to={item.to}
                        className={css({
                          textDecoration: "none",
                          display: "block",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionTimingFunction: "ease-in-out",
                          ":hover": {
                            opacity: "0.8",
                          },
                        })}
                      >
                        <LabelSmall
                          $style={{
                            color: theme.colors.white,
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "15px",
                            lineHeight: "26px",
                            [theme.breakpoints.mediaQueries.small]: {
                              lineHeight: "48px",
                            },
                          }}
                        >
                          {item.title}
                        </LabelSmall>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </Cell>
            <Cell span={[4, 3, 4]}>
              <div
                className={css({
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                })}
              >
                <div
                  className={css({
                    flexGrow: "1",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    [theme.breakpoints.mediaQueries.medium]: {
                      paddingTop: theme.sizing.scale600,
                      paddingBottom: theme.sizing.scale600,
                    },
                  })}
                >
                  <div
                    className={css({
                      backgroundColor: theme.colors.backgroundPrimary,
                      width: "50vw",
                      display: "block",
                      height: "100%",
                      position: "absolute",
                      borderBottomLeftRadius: "30px",
                      [theme.breakpoints.mediaQueries.medium]: {
                        width: "auto",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                        borderBottomLeftRadius: "0px",
                        left: "-16px",
                        right: "-16px",
                      },
                    })}
                  ></div>
                  <div
                    className={css({
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      position: "relative",
                    })}
                  >
                    <div
                      className={css({
                        display: "flex",
                        alignItems: "flex-end",
                      })}
                    >
                      <LabelXSmall
                        $style={{
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          lineHeight: "15px",
                          marginRight: theme.sizing.scale600,
                        }}
                      >
                        <ContentItem
                          scope="Footer"
                          accessor="shared.socialLabel"
                        />
                      </LabelXSmall>
                      <a
                        href={ContentObject.Shared.linkedInLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button kind="circle">
                          <img
                            alt="LinkedIn"
                            src={Social.LinkedIn}
                            width="16px"
                            height="16px"
                          />
                        </Button>
                      </a>
                    </div>
                    <div
                      className={css({
                        textAlign: "right",
                      })}
                    >
                      <a
                        href={ContentObject.Shared.clutchLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Clutch"
                          src={useDarkTheme ? clutchLogoWhite : clutchLogo}
                          height="18px"
                        />
                      </a>
                      <LabelXSmall
                        $style={{
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          marginTop: theme.sizing.scale100,
                        }}
                      >
                        <ContentItem
                          scope="Footer"
                          accessor="shared.clutchLabel"
                        />
                      </LabelXSmall>
                    </div>
                  </div>
                </div>
              </div>
            </Cell>
            <Cell span={[4, 5, 8]}>
              <div
                className={css({
                  position: "relative",
                })}
              >
                <div
                  className={css({
                    position: "absolute",
                    zIndex: "0",
                    right: "-36px",
                    left: "0px",
                    height: "20vh",
                    backgroundColor: theme.colors.black100,
                    borderTopRightRadius: "30px",
                    [theme.breakpoints.mediaQueries.large]: {
                      left: "-64px",
                      height: "40vh",
                    },
                  })}
                ></div>
              </div>
            </Cell>
            <Cell span={[4, 3, 4]}>
              <div
                className={css({
                  position: "relative",
                })}
              >
                <div
                  className={css({
                    position: "absolute",
                    zIndex: "0",
                    left: "0px",
                    height: "20vh",
                    width: "50vw",
                    backgroundColor: theme.colors.black100,
                    borderTopLeftRadius: "30px",
                  })}
                ></div>
              </div>
            </Cell>
            <Cell skip={[0, 0, 1]} span={[4, 5, 7]} order={[12, 10, 10]}>
              <LabelSmall
                $style={{
                  fontWeight: "400",
                  color: theme.colors.white,
                  marginTop: theme.sizing.scale800,
                  marginBottom: theme.sizing.scale800,
                  position: "relative",
                  [theme.breakpoints.mediaQueries.medium]: {
                    textAlign: "center",
                    marginTop: theme.sizing.scale200,
                  },
                }}
              >
                <ContentItem scope="Footer" accessor="shared.copyrights" />
              </LabelSmall>
            </Cell>
            <Cell span={[4, 3, 4]} order={[10, 12, 12]}>
              <div
                className={css({
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  marginTop: theme.sizing.scale800,
                  marginBottom: theme.sizing.scale800,
                })}
              >
                <LabelSmall
                  $style={{
                    fontWeight: "400",
                    color: theme.colors.white,
                  }}
                >
                  <ContentItem scope="Footer" accessor="shared.investorLabel" />
                </LabelSmall>

                <a
                  href={ContentObject.Footer.shared.investorUrl}
                  title={ContentObject.Footer.shared.investorName}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={atendeLogo}
                    alt={ContentObject.Footer.shared.investorName}
                    height="14px"
                    className={css({
                      marginLeft: theme.sizing.scale600,
                      filter: "brightness(0) invert(1)",
                    })}
                  />
                </a>
              </div>
            </Cell>
          </Grid>
        </div>
      </footer>
    );
  }
};

Footer.propTypes = {
  size: PropTypes.string,
};

Footer.defaultProps = {
  size: `regular`,
};

export default Footer;
